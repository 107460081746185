<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#005f32"
        spinner="bar-fade-scale"
      />
      <v-snackbar v-model="showsnackbar" color="black" right>
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showsnackbar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap>
        <v-flex>
          <!-- <v-card> -->
          <v-card-title class="elevation-0">
            <span class="itemHeading">MY WITHDRAWAL LEAVE LIST</span>
            <v-spacer></v-spacer>
       
            <v-flex xs12 align-self-center pt-2>
              <v-layout wrap justify-start>
                <v-flex xs12 text-left>
                  <span style="font-family: kumbhRegular; font-size: 14px"
                    >*Select the options to filter the results</span
                  >
                </v-flex>
                <v-flex xl3 lg3 md3 pb-2 sm6 xs12 pl-0 pl-xs-0 pl-sm-1 pl-md-1 pl-lg-1 pl-xl-1 pr-0 pr-xs-0 pr-sm-2 pr-md-2 pr-lg-2 pr-xl-2>
                  <v-select
                    :items="lists"
                    dense
                    v-model="status"
                    item-text="name"
                    item-value="value"
                    outlined
                    class="rounded-xl"
                  ></v-select>
                </v-flex>
  
                <v-flex xl3 lg3 md3 pb-2 sm6 xs12 px-0 px-xs-0 px-sm-2 px-md-2 px-lg-2 px-xl-2>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted"
                        outlined
                        hide-details
                        dense
                    class="rounded-xl"
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      type="month"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-card-title>
          <v-layout wrap justify-start v-if="leaveArray.length > 0">
            <v-flex xs12 sm12 md6 lg6 xl6 pa-5 v-for="(item, i) in leaveArray" :key="i">
              <v-card color="#ebe9e6" height="100%">
                <v-layout wrap pa-5>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex>
                        <span
                          style="
                            text-transform: uppercase;
                            font-family: kumbhBold;
                          "
                          >{{ item.leaveid.leaveCategory }} LEAVE &nbsp;</span
                        >
                        <span style="font-family: kumbhBold"
                          ><v-btn
                            rounded
                            elevation="0"
                            small
                            dark
                            :color="getStatusColor(item.withdrawnSelectedDatesStatus)"
                            >{{ item.withdrawnSelectedDatesStatus }}</v-btn
                          ></span
                        >
                      </v-flex>
                     
                      <v-flex text-right v-if="item.withdrawnSelectedDatesStatus == 'Withdrawn'">
                        <v-btn
                        @click="cancelLeave(item._id, item.leaveid._id)"
                        color="red"
                        dark
                        small
                      >
                        <span style="font-family: kumbhBold">
                          <v-icon small>mdi-close</v-icon> Cancel
                        </span>
                      </v-btn>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap py-5 justify-start>
                      <v-flex xs12 text-left>
                        <v-divider></v-divider>
                        <v-layout wrap py-3>
                          <v-flex xs12 sm6>
                            <span class="itemHeading2">
                              <v-icon small color="black">mdi-calendar</v-icon>
                              Date</span
                            >
                          </v-flex>
                          <v-flex xs12 sm6>
                            <span class="itemHeading2">
                              {{ formatDate(item.leaveid.create_date) }}</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <!-- <v-layout wrap py-3>
                          <v-flex xs12 sm6>
                            <span class="itemHeading2">
                              <v-icon small color="black">mdi-calendar</v-icon>
                              Required On</span
                            >
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                          >
                            <span class="itemHeading2" v-for="(lv, i) in item.leaveRequiredOn"
                            :key="lv">
                              {{ formatDate(lv) }}
                            <span v-if="i < item.leaveRequiredOn.length - 1"
                              >,</span
                            ></span
                            >
                          </v-flex>
                        </v-layout> -->
                        
                        <v-layout wrap py-3 align-center>
                          <v-flex xs12 sm6>
                            <span class="itemHeading2">
                              <v-icon small color="black">mdi-calendar</v-icon>
                              Required On
                            </span>
                          </v-flex>
                          <v-flex xs12 sm6>
                            <v-layout  align-center>
                              <v-flex>
  
                          
                              <span class="itemHeading2" v-for="(lv, i) in visibleDates(item)" :key="lv">
                                {{ formatDate(lv) }}
                                <span v-if="i < visibleDates(item).length - 1">,</span>
                              </span>
                              <v-btn
                                v-if="!item.showAllDates && item.leaveid.leaveRequiredOn.length > 1"
                                small
                                text class="ml-2"
                                color="blue"
                                @click="showMoreDates(item)"
                              >
                                See More
                              </v-btn>
                              <v-btn
                                v-if="item.showAllDates && item.leaveid.leaveRequiredOn.length > 1"
                                small
                                text
                                color="red"
                                class="ml-2"
                                @click="showLessDates(item)"
                              >
                                Close
                              </v-btn>
                            </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        
                        <v-divider></v-divider>
                        <v-layout wrap py-3>
                          <v-flex xs12 sm6>
                            <span class="itemHeading2">
                              <v-icon small color="black">mdi-calendar</v-icon>
                              No. of Day(s)</span
                            >
                          </v-flex>
                          <v-flex xs12 sm6>
                            <span class="itemHeading2">
                              {{ item.leaveid.leaveRequiredOn.length }}</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>

                        <v-layout wrap py-3>
                          <v-flex xs12 sm6>
                            <span class="itemHeading2">
                              <v-icon small color="black">mdi-calendar</v-icon>
                              Withdrawn Dates</span
                            >
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                          >
                          

                            <span class="itemHeading2" v-for="(lv, i) in visibleDates1(item)" :key="lv">
                              {{ formatDate(lv) }}
                              <span v-if="i < visibleDates1(item).length - 1">,</span>
                            </span>

                            <v-btn
                                v-if="!item.showAllDates1 && item.withdrawnSelecetedDates.length > 1"
                                small
                                text class="ml-2"
                                color="blue"
                                @click="showMoreDates1(item)"
                              >
                                See More
                              </v-btn>
                              <v-btn
                                v-if="item.showAllDates1 && item.withdrawnSelecetedDates.length > 1"
                                small
                                text
                                color="red"
                                class="ml-2"
                                @click="showLessDates1(item)"
                              >
                                Close
                              </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-layout wrap py-3>
                          <v-flex xs12 sm6>
                            <span class="itemHeading2">
                              <v-icon small color="black">mdi-note</v-icon>
                              Type</span
                            >
                          </v-flex>
                          <v-flex xs12 sm6 v-if="item.leaveid.leaveType=='FN'">
                            <span class="itemHeading2">
                              First Half</span
                            >
                          </v-flex>
                          <v-flex xs12 sm6 v-if="item.leaveid.leaveType=='AN'">
                            <span class="itemHeading2">
                            Second Half</span
                            >
                          </v-flex>
                          <v-flex xs12 sm6 v-if="item.leaveid.leaveType=='Fullday'">
                            <span class="itemHeading2">
                              Full Day</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-layout wrap py-3>
                          <v-flex xs12 sm6>
                            <span class="itemHeading2">
                              <v-icon small color="black"
                                >mdi-table-of-contents</v-icon
                              >
                              Purpose</span
                            >
                          </v-flex>
                          <v-flex xs12 sm6>
                            <span class="itemHeading2"> {{ item.leaveid.reason }}</span>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-layout wrap py-3 v-if="item.leaveid.status=='approved'">
                          <v-flex xs12 sm6>
                            <span class="itemHeading2">
                              <v-icon small color="black"
                                >mdi-file-question</v-icon
                              >
                               Approved By</span
                            >
                          </v-flex>
                          <v-flex xs12 sm6 v-if="item.leaveid.actionBy">
                            <span class="itemHeading2"> {{ item.leaveid.actionBy.name }}</span>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap py-3 v-if="item.leaveid.status=='rejected'">
                          <v-flex xs12 sm6>
                            <span class="itemHeading2">
                              <v-icon small color="black"
                                >mdi-file-question</v-icon
                              >
                               Rejected By</span
                            >
                          </v-flex>
                          <v-flex xs12 sm6 v-if="item.leaveid.actionBy">
                            <span class="itemHeading2"> {{ item.leaveid.actionBy.name }} (EmpID: {{ item.leaveid.actionBy.employeeId }})</span>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-layout wrap py-3 v-if="item.leaveid.status=='rejected'">
                          <v-flex xs12 sm6>
                            <span class="itemHeading2">
                              <v-icon small color="black"
                                >mdi-table-of-contents</v-icon
                              >
                              Remarks</span
                            >
                          </v-flex>
                          <v-flex xs12 sm6 v-if="item.remarks != '' && item.status=='rejected'">
                            <span class="itemHeading2"> {{ item.remarks }}</span>
                          </v-flex>
                          <v-flex xs12 sm6 v-else>
                            <span class="itemHeading2">No remarks</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap v-else>
            <v-flex xs12 align-self-center pa-16>
              <span class="kumbhRegular">No Data found!</span>
            </v-flex>
          </v-layout>
          <!-- </v-card> -->
        </v-flex>
      </v-layout>
  
      <v-layout wrap justify-center v-if="pages > 1">
        <v-flex align-self-center>
          <div class="pt-2">
            <v-pagination
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="#005f32"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
  
  
  
  
   
      <v-dialog persistent v-model="withdrawLeavesDialog" max-width="50%">
        <v-card>
          <v-layout wrap>
            <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
              <v-layout wrap>
                <v-flex xs6 align-self-center text-left>
                  <span class="kumbhBold" style="color: #ffffff; font-size: 20px">Withdraw Leave</span>
                </v-flex>
                <v-flex xs6 align-self-center text-right>
                  <v-btn icon color="white" text @click="withdrawLeavesDialog = false">
                    <v-icon color="#ffffff">mdi-close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 pa-4></v-flex>
            <v-flex xs12>
              <v-layout wrap px-5 pb-8>
                <v-flex xs12 align-self-center text-left>
                  <span class="kumbhBold" style="color: #000; font-size: 20px">
                    Select the dates to withdraw your leave:
                  </span>
                </v-flex>
      
                <!-- Select All Checkbox -->
                <v-flex xs12 align-self-center text-left pt-3>
                  <v-checkbox
                    v-model="selectAll"
                    label="Select All" class="kumbhRegular"
                    @change="toggleSelectAll"
                  />
                </v-flex>
      
                <!-- Individual Date Selection -->
                <v-flex xs12 align-self-center text-left pt-3>
                  <v-layout wrap>
                    <v-flex v-for="(date, index) in withdrawableDates" :key="index" xs4>
                      <v-checkbox
                        :label="formatDate(date)"
                        :value="date"
                        v-model="selectedWithdrawDates"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                
                <v-flex xs12 align-self-center text-left pt-3>
                  <span class="kumbhRegular">Reason For Withdraw</span>
                  <v-text-field outlined dense  class="pt-2 kumbhRegular" v-model="withdrawReason"></v-text-field>
                </v-flex>
      
                <!-- Display Selected Dates -->
                <p v-if="selectedWithdrawDates.length > 0">
                  Selected Dates: 
                  {{ selectedWithdrawDates.map(date => formatDate(date)).join(', ') }}
                </p>
      
                <!-- Withdraw Button -->
                <v-flex xs12 text-right pt-4>
                  <v-btn
                    color="red"
                    dark
                    @click="handleWithdraw"
                    :disabled="selectedWithdrawDates.length === 0"
                  >
                    Withdraw
                  </v-btn>
                </v-flex>
      
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      
  
      
      <!-- <v-dialog persistent v-model="withdrawLeavesDialog" max-width="50%">
        <v-card >
          <v-layout wrap>
            <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
              <v-layout wrap>
                <v-flex xs6 align-self-center text-left>
                  <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Withdraw Leave
                  </span>
                </v-flex>
                <v-flex xs6 align-self-center text-right>
                  <v-btn icon color="white" text @click="withdrawLeavesDialog = false">
                    <v-icon color="#ffffff">mdi-close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 pa-4></v-flex>
            <v-flex xs12>
              <v-layout wrap px-5 pb-8>
                <v-flex xs12 align-self-center text-left>
                  <span class="kumbhBold" style="color: #000; font-size: 20px">Do you want to withdraw your leaves
                    target?</span>
                </v-flex>
             <v-flex xs12 text-left>
                  <span class="itemText2">Select Leave Dates   {{ itemValue.leaveCategory }}</span>
                 <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dateArray"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-combobox v-model="formattedDates" multiple chips small-chips dense outlined readonly
                    v-bind="attrs" v-on="on">
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="item" close @click:close="removeDate(index)" class="ma-2" outlined>
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                  </template>
                
                  <v-date-picker
                    v-if="itemValue.leaveCategory == 'Casual' || itemValue.leaveCategory=='Festival'"
                    v-model="dateArray"
                    multiple
                     color="green"
                     scrollable
                      :allowed-dates="disablePastDates"
                  ></v-date-picker>
              
                  <v-date-picker
                    v-else-if="itemValue.leaveCategory==='Special' || itemValue.leaveCategory==='Maternity' || itemValue.leaveCategory==='Paternity'"
                    v-model="dateArray"
                    range
                    color="primary"
                    scrollable no-title
                  :allowed-dates="disablePastDates"
                  ></v-date-picker>
              
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(dateArray)">OK</v-btn>
                </v-menu>
                </v-flex>
            
                <v-flex xs12 align-self-center text-right pt-5>
                  <v-btn @click="approveTarget()" color="#005f32">
                    <span class="kumbhBold" style="color: white"> Approve Target </span>
                  </v-btn></v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog> -->
  
  
  
    </div>
  </template>
      <script>
  import axios from "axios";
  export default {
    data: (vm) => ({
      showsnackbar: false,
      ServerError: false,
      withdrawLeavesDialog:false,
      msg: null,
      pages: 0,
      dialog: false,
      pageCount: 1,
      appLoading: false,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      itemValue:"",
      rules: {
        required: (value) => !!value || "Required.",
      },
      status: "",
      lists: [
        {
          name: "All",
          value: "",
        },
        // { name: "Pending", value: "Pending" },
        { name: "Approved", value: "Approved" },
        { name: "Rejected", value: "Rejected" },
        { name: "Withdrawn", value: "Withdrawn" },
  
      ],
      leaveArray: [],
      data: [],
      name: null,
      assetId: null,
      date: new Date().toISOString().substr(0, 7),
      dateFormatted: vm.formatDateMonth(new Date().toISOString().substr(0, 7)),
      menu1: false,
      menu2: false,
  
  
      withdrawReason:"",
      selectAll: false,
      withdrawableDates: [], // Store filtered dates
      selectedWithdrawDates: []
  
  
    }),
    computed: {
      computedDateFormatted() {
        return this.formatDateMonth(this.date);
      },
    },
    mounted() {
      this.getData();
    },
    watch: {
      // dateArray(val) {
      //   if (Array.isArray(val) && val.length === 2 && this.leaveCategory !== "Casual" && this.leaveCategory !== "Festival") {
      //     let [start, end] = val;
  
      //     if (new Date(start) > new Date(end)) {
      //       [start, end] = [end, start]; // Swap dates if needed
      //     }
  
      //     // Generate all dates in the selected range
      //     this.dateArray = this.getDatesInRange(start, end);
      //     this.formattedDates = this.dateArray.map(date => this.formatDate(date));
      //   } else if (Array.isArray(val)) {
      //     this.formattedDates = val.map(date => this.formatDate(date));
      //   } else {
      //     this.formattedDates = [];
      //   }
      // },
      date() {
        this.currentPage = 1;
        this.dateFormatted = this.formatDateMonth(this.date);
        if (this.computedDateFormatted) {
          this.getData();
        }
      },
      status() {
        this.currentPage = 1;
        this.getData();
      },
      currentPage() {
        this.getData();
      },
    },
  
    methods: {
      toggleSelectAll() {
      if (this.selectAll) {
        this.selectedWithdrawDates = [...this.withdrawableDates]; // Select all
      } else {
        this.selectedWithdrawDates = []; // Deselect all
      }
    },
    handleWithdraw() {
      if (this.selectedWithdrawDates.length === this.withdrawableDates.length) {
        this.withdrawAllLeaves(this.itemValue._id); // Withdraw all leaves
      } else {
        this.withdrawLeaves(this.itemValue._id); // Withdraw selected dates
      }
    },
    withdrawAllLeaves(itemId) {
      axios({
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        url: "/leave/application/withdraw/" + itemId,
      })
      .then((response) => {
            if (response.data.status) {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showsnackbar=true;
              this.withdrawLeavesDialog=false;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar=true
            }
          })
          .catch((err) => {
            this.visible = false;
            this.errors.push(err);
          });
      },
  
    // API Call for Withdrawing Selected Leaves
    withdrawLeaves(itemId) {
      axios({
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id:itemId,
          withdrawRequiredOn: this.selectedWithdrawDates,
          withdrawReason: this.withdrawReason,
        
        },
        url: "/selected/date/leave/application/withdraw",
      })
      .then((response) => {
            if (response.data.status) {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.withdrawLeavesDialog=false;
              this.showsnackbar=true
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar=true
            }
          })
          .catch((err) => {
            this.visible = false;
            this.errors.push(err);
          });
      },
  
    // Handle API Response
    handleResponse(response) {
      if (response.data.status) {
        this.msg = response.data.msg;
        this.showsnackbar = true;
        this.getData(); // Refresh data
      } else {
        this.msg = response.data.msg;
        this.showsnackbar = true;
      }
    },
      removeDate(index) {
      this.itemValue.leaveid.leaveRequiredOn.splice(index, 1);
    },
    getWithdrawableDates(item) {
      const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
      return item.leaveid.leaveRequiredOn.filter(date => date.split('T')[0] !== today);
    },
    
   
    // withdrawLeaves() {
    //   // Call API to withdraw the remaining leave dates
    //   axios.post('/withdraw-leave', { leaveId: this.itemValue._id, leaveDates: this.itemValue.leaveRequiredOn })
    //     .then(response => {
    //       this.withdrawLeavesDialog = false;
    //       this.$emit('leaveWithdrawn', this.itemValue._id);
    //     })
    //     .catch(error => {
    //       console.error("Error withdrawing leave:", error);
    //     });
    // },
      // getDatesInRange(start, end) {
      //   let dateArray = [];
      //   let currentDate = new Date(start);
  
      //   while (currentDate <= new Date(end)) {
      //     dateArray.push(currentDate.toISOString().split("T")[0]); // Store as YYYY-MM-DD
      //     currentDate.setDate(currentDate.getDate() + 1);
      //   }
  
      //   return dateArray;
      // },
      // removeDate(index) {
      //   // Remove the selected date from dateArray
      //   this.dateArray.splice(index, 1);
  
      //   // Update formattedDates to reflect the removal
      //   this.formattedDates = this.dateArray.map(date => this.formatDate(date));
      // },
  
  
    //   disablePastDates(date) {
    //   const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    //   return date > today; // Allow only future dates
    // },
      visibleDates(item) {
        return item.showAllDates ? item.leaveid.leaveRequiredOn : item.leaveid.leaveRequiredOn.slice(0, 1);
      },
      visibleDates1(item) {
        return item.showAllDates1 ? item.withdrawnSelecetedDates : item.withdrawnSelecetedDates.slice(0, 1);
      },
    
      showMoreDates(item) {
        // Show all dates
        this.$set(item, 'showAllDates', true);
      },
      showLessDates(item) {
        // Show only the first two dates
        this.$set(item, 'showAllDates', false);
      },

      showMoreDates1(item) {
        // Show all dates
        this.$set(item, 'showAllDates1', true);
      },
      showLessDates1(item) {
        // Show only the first two dates
        this.$set(item, 'showAllDates1', false);
      },
      getStatusColor(withdrawnSelectedDatesStatus) {
        if (withdrawnSelectedDatesStatus === "Approved") {
          return "success";
        }
       
        if (withdrawnSelectedDatesStatus === "Pending") {
          return "info";
        }
        if (withdrawnSelectedDatesStatus === "Withdrawn") {
          return "#095e05";
        }
      },
      getData() {
        this.appLoading = true;
        axios({
          url: "/my/selected/date/leave/application/list",
          method: "POST",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            page: this.currentPage,
            limit: 20,
            month: this.computedDateFormatted,
            status: this.status,
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.leaveArray = response.data.leaveWithdraw;
            this.totalData = response.data.totalLength;
            this.pages = Math.ceil(this.totalData / response.data.limit);
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      cancelLeave(withdrawId, leaveId) {
  axios({
    method: "POST",
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
    url: "/selected/date/leave/application/delete",
    data: {
      withdrawId: withdrawId,
      id: leaveId
    }
  })
    .then((response) => {
      if (response.data.status) {
        this.appLoading = false;
        this.msg = response.data.msg;
        this.showsnackbar = true;
        this.getData();
      } else {
        this.msg = response.data.msg;
        this.showsnackbar = true;
      }
    })
    .catch((error) => {
      console.error("Error canceling leave:", error);
    });
},
     
      formatDate(item) {
    const dt = new Date(item);
    const day = String(dt.getDate()).padStart(2, '0'); // Get day with leading zero
    const month = String(dt.getMonth() + 1).padStart(2, '0'); // Get month with leading zero
    const year = dt.getFullYear(); // Get full year
    return `${day}-${month}-${year}`;
  },
  
      formatDateMonth(date) {
        if (!date) return null;
        const [year, month] = date.split("-");
        return `${month}-${year}`;
      },
    },
  };
  </script>
    <style>
  .tableHead {
    font-size: 15px;
    color: #ffffff;
    font-family: kumbhMedium;
  }
  </style>
      